import React from "react";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";
import PageTitle from "components/page-title/page-title";
import { repositoryConfig } from "utils/repositoryConfig";

const PreviewPage = ({ isPrismicPreview }) => {
  return <>{isPrismicPreview && <PageTitle>Loading preview...</PageTitle>}</>;
};

export default withPrismicPreviewResolver(PreviewPage, repositoryConfig);
